import React, { useState } from 'react';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler, 
  Nav, 
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import Link from './link' 
import logo from '../assets/images/logo-head.png' 
import { FaPhone, FaEnvelope } from 'react-icons/fa'

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar color="light" light expand="md"> 
      <Container>
		<ul className="navbar-nav bd-navbar-nav flex-row navbar-right top-black md-none">
			<li  className='nav-item nav-right'>
			  <Link to="http://yard.agwarehouse-chb.com:8080/" className="btn btn-blue">Track Shipment</Link>
			</li>
			<li  className='nav-item nav-right'>
			  <Link to="http://yard.gutierreztrucking.com:8080/" className="btn btn-blue">Yard Inventory</Link>
			</li>
			<li  className='nav-item nav-right'>
					
			  <Link to="https://aceweb.rbsystems.com/AGWHSEWEB/Login.aspx" className="btn btn-blue">Warehouse Inventory</Link>
			</li> 
			<li  className='nav-item nav-right'>
			  <Link to="/quotes" className="btn btn-blue">Get a Quote</Link>
			</li> 
		</ul>
		<ul className="navbar-nav bd-navbar-nav flex-row navbar-right  md-none">
			<li  className='nav-item nav-right' >
			  <Link to="/career" className="btn btn-red"> Driver Application </Link>
			</li>
			<li  className='nav-item nav-right' >
			  <Link to="/career" className="link-blue active"> Join our team </Link>
			</li>  
			<li  className='nav-item nav-right line-white' >
			   
			</li>
			<li  className='nav-item nav-right' >
				<a href="tel:18882561210" className="link-blue active border-button"><FaPhone className="rotate  mr-1" /> 1 (888) 256 - 1210</a>
			</li>
			<li  className='nav-item nav-right' >
				<a href="mailto:info@gutierreztruckin.com" className="link-blue active"><FaEnvelope className="  mr-1"/> info@gutierreztrucking.com</a>
			</li>
		</ul>
        <Link to="/" className="navbar-brand">
          <img src={logo} className="logo" alt="Logo"/>
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
			<Nav className="ml-auto" navbar>
				<li className="nav-item">
					<a className="nav-link" href="/aboutus">ABOUT US</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="/services">SERVICES</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="/equipment">EQUIPMENT</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="/locations">LOCATIONS</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="/technology">TECHNOLOGY</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="/career">CAREER</a>
				</li>
				<li className="nav-item">
					<a aria-current="page" className="nav-link" href="/contactus">CONTACT US</a>
				</li>
				<UncontrolledDropdown nav inNavbar>
				  <DropdownToggle nav caret>
					
				  </DropdownToggle>
				  <DropdownMenu right>
					<DropdownItem href="/howis">
					  HOW'S OUR DRIVING ?
					</DropdownItem>
					<DropdownItem href="/complaints">
					  COMPLAINTS
					</DropdownItem> 
					<DropdownItem href="/application">
					  CREDIT APPLICATION
					</DropdownItem>
				  </DropdownMenu>
				</UncontrolledDropdown>
			</Nav>
			<Link to="/ESP/" className="navbar-brand lenguage esp">
			  ESP
			</Link>
			<Link to="/" className="navbar-brand lenguage eng">
			  ENG
			</Link>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
